









import Vue from "vue";
import { FNS_STATUSES } from "@/store/modules/application-list/application-list.constants";

export default Vue.extend({
  name: "TableStatusFNS",
  props: {
    fnsStatusId: {
      type: Number,
      required: true
    }
  },
  computed: {
    getStatus(): Object {
      const status = Object.values(FNS_STATUSES).find((s: any) => s.id === this.fnsStatusId);
      if (status && 
        [
          FNS_STATUSES.CheckStarted.id,
          FNS_STATUSES.Found.id,
          FNS_STATUSES.CheckError.id,
          FNS_STATUSES.FoundAndRevoked.id
        ].includes(status.id)
      )
      {
        return {title: status.title, color: status.color};
      }
      return {title: "", color: ""};
    }
  }
})
