export const FNS_STATUSES = {
  NoNeed: {
    id: 0,
    title: "Проверка существования сертификата в ФНС для заявки не требуется",
    color: ""
  },
  CheckStarted: {
    id: 1,
    title: "Отправлена проверка в ФНС",
    color: "rgb(173,173,29)"
  },
  NotFound: {
    id: 2,
    title: "В ФНС нет сертификата с данными заявки",
    color: ""
  },
  Found: {
    id: 3,
    title: "Найдены сертификаты ФНС",
    color: "#f5383b"
  },
  CheckError: {
    id: 4,
    title: "Ошибка проверки сертификата в ФНС",
    color: "#f5383b"
  },
  IgnoredByPartner: {
    id: 5,
    title: "Филиал проигнорировал существование сертификата в ФНС",
    color: ""
  },
  FoundAndRevoked: {
    id: 6,
    title: "Найдены сертификаты ФНС, но есть заявление на отзыв",
    color: "#f5383b"
  },
  Cancelled: {
    id: 7,
    title: "Проверки отменены, выпуск в ФНС принудительно разрешен",
    color: ""
  },
};
