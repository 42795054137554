





























































































import Vue from "vue";
import IconBase from "@/components/UI/IconBase.vue";
import Comment from "@/components/UI/icon/Comment.vue";
import Comp from "@/components/UI/icon/Comp.vue";
import Usb from "@/components/UI/icon/Usb.vue";
import Archive from "@/components/UI/icon/Archive.vue";
import PanelSMEV from "@/components/UI/table/PanelSMEV.vue";
import TableStatusFNS from "@/components/UI/table/TableStatusFNS.vue";

export default Vue.extend({
  name: "TableRow",

  components: {
    IconBase,
    Comment,
    Comp,
    Usb,
    Archive,
    PanelSMEV,
    TableStatusFNS
  },

  props: {
    items: {
      type: Array,
      required: true
    }, 
    isTHead: {
      type: Boolean,
      default: false
    },
    cellsWidth: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      activeTooltipComment: false,
      activeTooltipManagerComment: false
    }
  }, 

  methods: {
    showComment(text: string) {
      this.$modal.show("dialog", {
        title: "Комментарий администратора",
        text,
        buttons: [
          {
            title: "Закрыть",
            default: true,
            handler: () => {
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    },
    showManagerComment(text: string) {
      this.$modal.show("dialog", {
        title: "Комментарий менеджера",
        text,
        buttons: [
          {
            title: "Закрыть",
            default: true,
            handler: () => {
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    }
  },
})
