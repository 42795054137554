










































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ChevronLeft from "@/components/UI/icon/ChevronLeft.vue";
import IconBase from "@/components/UI/IconBase.vue";

@Component({
  components: {
    IconBase,
    ChevronLeft
  }
})
export default class Pager extends Vue {
  @Prop({ type: Number, default: 1 }) public totalPages!: number;
  @Prop({ type: Number, default: 1 }) public page!: number;
  @Prop({ type: Number, default: 3, validator: size => size > 0 })
  public size!: number;

  private aProps = {
    href: "javascript:void(0);",
    role: "button"
  };
  private pageNumber = 0;

  public get curentPage(): number {
    if (this.page !== this.pageNumber) {
      this.pageNumber = this.page;
    }
    return this.pageNumber;
  }

  public onClickHandler(page: number) {
    if (typeof page === "number") {
      this.pageNumber = page;
    }
  }

  public nextHandler() {
    const pages = this.pageNumber;
    this.pageNumber = pages >= this.totalPages ? pages : pages + 1;
  }

  public prevHandler() {
    const pages = this.pageNumber;
    this.pageNumber = pages <= 1 ? 1 : pages - 1;
  }

  get pages() {
    const filteredPages: any = this.filteredPages;
    const pages = filteredPages
      ? [
          filteredPages[0] - 1 === 1 ? 1 : "...",
          ...filteredPages,
          filteredPages[filteredPages.length - 1] + 1 === this.totalPages - 2
            ? this.totalPages - 2
            : "..."
        ]
      : [...Array(this.totalPages).keys()].map(page => page + 1);
    return filteredPages
      ? [0, ...pages, this.totalPages - 1].map(page => {
          if (page === "...") {
            return page;
          }
          return page + 1;
        })
      : pages;
  }

  get filteredPages() {
    const diff = this.size / 2;
    const toFilterPages = [...Array(this.totalPages).keys()].slice(2, -2);

    if (toFilterPages.length > this.size) {
      const diffFirst = this.pageNumber - 1 - toFilterPages[0];
      const diffLast =
        this.pageNumber - 1 - toFilterPages[toFilterPages.length - 1];
      if (diffFirst < diff) {
        return toFilterPages.slice(0, this.size);
      } else if (diffLast >= -diff) {
        return toFilterPages.slice(-this.size);
      } else {
        return toFilterPages.filter(page => {
          const diffPage = this.pageNumber - 1 - page;
          return diffPage < 0 ? Math.abs(diffPage) <= diff : diffPage < diff;
        });
      }
    }
  }

  @Watch("pageNumber")
  public paginate(newPage: number) {
    this.$emit("paginate", newPage);
  }
}
