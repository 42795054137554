







import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "Plus" })
export default class Plus extends Vue {}
