










































import { Vue, Component, Prop } from "vue-property-decorator";
import IconBase from "@/components/UI/IconBase.vue";
import Comment from "@/components/UI/icon/Comment.vue";
import Comp from "@/components/UI/icon/Comp.vue";
import Usb from "@/components/UI/icon/Usb.vue";
import Archive from "@/components/UI/icon/Archive.vue";

@Component({ components: { IconBase, Comment, Comp, Usb, Archive } })
export default class ListRequestsItem extends Vue {
  @Prop(Array) public items!: [];

  private activeTooltip = false;

  public comment(text: string) {
    this.$modal.show("dialog", {
      title: "Комментарий администратора",
      text,
      buttons: [
        {
          title: "Закрыть",
          default: true,
          handler: () => {
            this.$modal.hide("dialog");
          }
        }
      ]
    });
  }
}
