<template>
  <div class="panel-smev">
    <div 
      v-for="item in statusSMEV" 
      :key="item.name" 
      :class="['panel-smev-item', `status_${item.status}`]"
    >
      <span
        @mouseenter="onMouseEnter(item.name)"
        @mouseleave="onMouseLeave(item.name)"
      >{{item.name}}</span>

      <transition name="fade">
        <div v-if="showTooltip[item.name]" class="panel-smev-tooltip">
          <span>{{item.name}}</span>
          <span>{{item.comment}}</span>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelSMEV',

  props: {
    statusSMEV: {
      type: Array,
      default: [],
      required: true
    },
  },

  data() {
    return {
      showTooltip: {},
    }
  },

  mounted() {
    if(this.statusSMEV) {
      this.statusSMEV.forEach(item => {
        this.showTooltip[item.name] = false;
      })
    }
  },

  methods: {
    onMouseEnter (name) {
      this.showTooltip = {...this.showTooltip, [name]: true}
    },
    onMouseLeave (name) {
      this.showTooltip = {...this.showTooltip, [name]: false}
    }
  },
}
</script>

<style lang="scss">
  .panel-smev {
    padding: 7px 0;
    line-height: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .panel-smev-item {
    position: relative;
    font-size: 1em;
    font-weight: 400;
    padding: 0 2px 4px 2px;
    &:hover {
      font-weight: 700;
    }
    &.status_-1 {
      color: #f5383b;
    }
    &.status_1 {
      color: #10b487;
    }
    &.status_0, &.status_2 {
      color: #CCCC00;
    }
    &.status_-2 {
      color: inherit;
    }
  }

  .panel-smev-tooltip {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    right: -15px;
    z-index: 1;
    transform: translate(100%, -50%);
    padding: 10px 15px 10px 10px;
    max-width: 180px;
    min-width: 140px;
    background: #6f61e9;
    opacity: 1;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    text-align: left;
    line-height: 17px;
    color: #fff;
    span:first-child {
      margin-bottom: 7px;
    }
    &.fade-enter-active,
    &.fade-leave-active {
      transition: 0.3s ease;
      transition-property: opacity, transform;
    }
    &.fade-enter,
    &.fade-leave-to {
      transform: translate(120%, -50%);
      opacity: 0;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-left: -7px;
      margin-top: -9px;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 8px solid #6f61e9;
    }
  }
</style>