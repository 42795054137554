import { Module } from "vuex";

import { RootState } from "@/store/root.interface";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

import { MIN_CONTAINER_WIDTH } from "@/constants";

export type TContentType = "app" | "preapp";
export interface ITableState {
  containerWidth: {
    app: number;
    preapp: number
  };
  columns: {
    app: any[],
    preapp: any[]
  };
  contentType: TContentType;
};

export const defaultColumns = {
  app: [
    {title: "№", part: 0.06},
    {title: "Создана", part: 0.06},
    {title: "Статус", part: 0.07},
    {title: "Оплата", part: 0.06},
    {title: "Дата оплаты", part: 0.07},
    {title: "Организация", part: 0.09},
    {title: "ФИО", part: 0.08},
    {title: "Тип заявителя", part: 0.1},
    {title: "Телефон", part: 0.07},
    {title: "В заказе", part: 0.18},
    {title: "Итоговая стоимость", part: 0.09},
    {title: "Менеджер", part: 0.07},
  ],
  preapp: [
    {title: "№", part: 0.06},
    {title: "Создана", part: 0.06},
    {title: "Статус", part: 0.07},
    {title: "Организация", part: 0.09},
    {title: "ФИО", part: 0.08},
    {title: "Тип заявителя", part: 0.1},
    {title: "Телефон", part: 0.07},
    {title: "В заказе", part: 0.18},
    {title: "Итоговая стоимость", part: 0.09},
  ],
};

export const tableState: Module<ITableState, RootState> = {
  namespaced: true,
  state: {
    containerWidth: {
      app: MIN_CONTAINER_WIDTH,
      preapp: MIN_CONTAINER_WIDTH
    },
    columns: JSON.parse(JSON.stringify(defaultColumns)),
    contentType: "app"
  },
  mutations,
  actions,
  getters
};