import { GetterTree } from "vuex";

import { ITableState } from "./";
import { RootState } from "@/store/root.interface";
import { CONTAINER_PADDINGS } from "@/constants";

export const getters: GetterTree<ITableState, RootState> = {
  getTableWidth(state) {
    return {
      app: state.containerWidth["app"] - CONTAINER_PADDINGS,
      preapp: state.containerWidth["preapp"] - CONTAINER_PADDINGS
    };
  },

  getColumnTitles(state) {
    return state.columns[state.contentType].map(c => c.title);
  },
}
