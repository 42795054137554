





















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapState, mapGetters } from "vuex";
import cookies from "js-cookie";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import Button from "@/components/UI/Button.vue";
import Table from "@/components/UI/table/Table.vue";
import Thead from "@/components/UI/table/Thead.vue";
import Tbody from "@/components/UI/table/Tbody.vue";
import TableRow from "@/components/UI/table/TableRow.vue";
import Cell from "@/components/UI/table/Cell.vue";
import Select from "@/components/UI/form/Select.vue";
import Pager from "@/components/UI/Pager.vue";
import Loader from "@/components/UI/Loader.vue";
import Filters from "@/components/Filters.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Plus from "@/components/UI/icon/Plus.vue";
import Input from "@/components/UI/form/Input.vue";
import { defaultColumns } from "@/store/modules/table";

@Component({
  computed: {
    ...mapState("applicationList", [
      "sizePage",
      "activePage",
      "totalPages",
      "isLoaded",
      "isArchive",
      "filterControls"
    ]),
    ...mapGetters("applicationList", ["getApplicationList", "isActiveFilter"])
  },
  methods: {
    ...mapActions("applicationList", [
      "getPreApplication",
      "changePage",
      "changeSizePage",
      "setArchive",
      "clearFilter",
      "setActivePage",
      "applyFilter",
      "inputHandler"
    ]),
    ...mapActions("application", ["getApplication"])
  },
  components: {
    Loader,
    Button,
    Tbody,
    Thead,
    Table,
    TableRow,
    Cell,
    Select,
    Pager,
    Filters,
    PageLayout,
    Header,
    Footer,
    IconBase,
    Plus,
    Input
  }
})
export default class Home extends Vue {
  public thead = [
    "№",
    "Создана",
    "Статус",
    "Организация",
    "ФИО",
    "Тип заявителя",
    "Телефон",
    "В заказе",
    "Итоговая стоимость"
  ];
  public getPreApplication!: (savePagination: any) => void;
  public setArchive!: (val: boolean) => void;
  public setActivePage!: (num: number) => void;
  public clearFilter!: () => void;

  get savePagination() {
    return this.$route.params.savePagination || false;
  }

  public initialApplication() {
    if (this.$router.currentRoute.name === "archive") {
      this.setArchive(true);
    } else {
      this.setArchive(false);
    }

    this.getPreApplication(this.savePagination);
  }

  get getDefaultCellsWidth() {
    return defaultColumns["preapp"].map((column, index) => (
      window.localStorage.getItem(`app-column-${index}-width`) ||
      column.part * 100 + "%"
    ))
  }

  public mounted() {
    this.initialApplication();
  }

  @Watch("$route")
  public beforeRouteUpdate(to: any, from: any, next: any) {
    this.initialApplication();
  }
}
