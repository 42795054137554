













































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";

import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
import Button from "./UI/Button.vue";
import IconBase from "./UI/IconBase.vue";
import Setings from "./UI/icon/Setings.vue";
import Checkbox from "./UI/form/Checkbox.vue";
import Input from "./UI/form/Input.vue";

@Component({
  components: { Button, IconBase, Setings, Checkbox, Input, Datepicker },
  computed: {
    ...mapState("applicationList", ["filterControls", "isOpenFilter"])
  },
  methods: {
    ...mapActions("applicationList", [
      "inputHandler",
      "clearFilterHandler",
      "toggleFilter",
      "applyFilter"
    ])
  }
})
export default class Filters extends Vue {
  @Prop(Object) public options!: object;
  @Prop({ type: [Boolean], default: true }) public status!: boolean;
  @Prop({ type: [Boolean], default: true }) public applicant!: boolean;
  @Prop({ type: [Boolean], default: true }) public periodСreation!: boolean;
  @Prop({ type: [Boolean], default: true }) public periodIssue!: boolean;

  private inputHandler!: (evt: any) => void;
  private toggleFilter!: (val: boolean) => void;
  private ru = ru;

  private dateHendler(name: string, value: string) {
    const evt = {
      target: {
        type: "date",
        name,
        value
      }
    };

    this.inputHandler(evt);
  }

  private destroyed() {
    this.toggleFilter(false);
  }
}
