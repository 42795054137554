import { MutationTree } from "vuex";
import { ITableState, TContentType } from "./";

export const SET_CONTAINER_WIDTH = "SET_CONTAINER_WIDTH";
export const REMOVE_TABLE_COLUMN = "REMOVE_TABLE_COLUMN";
export const SET_TABLE_CONTENT_TYPE = "SET_TABLE_CONTENT_TYPE";

export const mutations: MutationTree<ITableState> = {
  [SET_CONTAINER_WIDTH](state, val: {contentType: TContentType, containerWidth: string}) {
    const {contentType, containerWidth} = val;
    const width = parseInt(containerWidth.replace("px", ""), 10);
    state.containerWidth[contentType] = width;
  },
  [REMOVE_TABLE_COLUMN](state, columnTitle: string) {
    const index = state.columns[state.contentType].findIndex(c => c.title == columnTitle);
    if (index !== -1) {
      // если этот столбец есть, удаляем его и прибавляем его ширину к столбцу "В заказе"
      const part = state.columns[state.contentType][index].part;
      state.columns[state.contentType].splice(index, 1);
      state.columns[state.contentType].find(c => c.title == "В заказе").part += part;
    }
    
  },
  [SET_TABLE_CONTENT_TYPE](state, contentType: TContentType) {
    state.contentType = contentType;
  }
};